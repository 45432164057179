import React, { useState, useEffect } from 'react';
import 'assets/styles/pages/sidebar.scss';
import 'assets/styles/pages/dashboardstatus.scss';
import { Modal, Spinner } from 'react-bootstrap';
import { Link, navigate } from 'gatsby';
import Form from 'react-bootstrap/Form';
import donatedtree from 'assets/images/admin/donated-tree.svg';
import editiconpencil from 'assets/images/admin/edit-icon.svg';
import binicon from 'assets/images/admin/recycle-bin.png';
//import sidebar css from react-pro-sidebar module and our custom css
import 'react-pro-sidebar/dist/css/styles.css';
import 'assets/styles/pages/customheader.scss';

import AdminService from 'services/admin/admin.service';
import { toast, ToastContainer } from 'react-toastify';

import Pagination from "react-js-pagination";

import noimage from "assets/images/noImage.png";
import { doAdminLogout } from './login';

export default function Adminstatuspage() {
  const adminApi = new AdminService();
  const [speciesList, setSpeciesList] = useState([]);
  const [specieName, setSpecieName] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [onSearch, setOnSearch] = useState(false);

  const deleteProject = async (id) => {
    setLoading(true);
    await adminApi
      .deleteAdminSpecies(id)
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          toast.success("Species Deleted Successfully");
          listAdminList();
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };
  const handlePageChange = async (pageNumber) => {
    await setActivePage(pageNumber);
    let inputs = {
      pageNumber: pageNumber - 1,
      pageSize: 10,
      name: specieName,
      status: statusValue,
      toBeFiltered: statusValue !== "" || specieName !== "" ? true : false,
    };
    setLoading(true);
    await adminApi
      .listAdminSpecies({ inputs })
      .then((data) => {
        if (data.status === 200) {
          setSpeciesList(data.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
  };

  const validationMessage = (data) => {
    if (data.response.status === 401) {
      toast.error("Authentication failed");
      setTimeout(() => {
        doAdminLogout();
        navigate("/admin/");
      }, 3000);
    } else if (data.response.status === 403) {
      toast.error("Un Authorized user");
      setTimeout(() => {
        doAdminLogout();
        navigate("/admin/");
      }, 3000);
    } else {
      toast.error(data.response.data.message);
    }
  };

  const listAdminList = async () => {
    let inputs = {
      pageNumber: 0,
      pageSize: 10,
      name: "",
      status: "",
      toBeFiltered: false,
    };
    setLoading(true);
    await adminApi
      .getAdminList({ inputs })
      .then((data) => {
        if (data.status === 200) {
          setSpeciesList(data.data);
          setActivePage(1);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          validationMessage(error);
        }
      });
  };

  const editAdmin = (data) => {
    let registerData = data;
    let path1 = "/admin/adminedit";
    let pathSet = `?id=${registerData.id}`;
    navigate(path1 + pathSet);
    // navigate("/admin/adminedit", { state: { editData } })
  };
  const searchSpecies = async (e) => {
    const value = e.target.value;
    setSpecieName(value);

    if (value.length > 2) {
      let inputs = {
        pageNumber: 0,
        pageSize: 100,
        name: value,
        status: statusValue,
        toBeFiltered: true,
      };
      setLoading(true);
      await adminApi
        .getAdminList({ inputs })
        .then((data) => {
          if (data.status === 200) {
            setSpeciesList(data.data);
            setActivePage(1);
            setLoading(false);
          } else {
            setLoading(false);
            toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            validationMessage(error);
          }
        });
    }
    if (value.length === 0) {
      setStatusValue("");
      listAdminList();
    }
  };
  const statusSearch = async (e) => {
    const value = e.target.value;
    setStatusValue(value);
    let inputs = {
      pageNumber: 0,
      pageSize: 100,
      name: specieName,
      status: value,
      toBeFiltered: value === "" ? false : true,
    };
    setLoading(true);
    await adminApi
      .getAdminList({ inputs })
      .then((data) => {
        if (data.status === 200) {
          setSpeciesList(data.data);
          setActivePage(1);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          validationMessage(error);
        }
      });
  };
  useEffect(() => {
    listAdminList();
  }, []);
  return (
    <>
      <div className="dashboard-status-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="dark-theme-green margin-btm"> ADMIN LIST</div>
          </div>
          <div className="col-md-3">
            <div className="project-status-select">
              <Form.Control
                className={
                  onSearch === true
                    ? "projectselect"
                    : "projectselect search-box-input"
                }
                type="text"
                placeholder=""
                onChange={(e) => {
                  searchSpecies(e);
                  setOnSearch(true);
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="project-status-select">
              <Form.Select
                className="all-status-input-box"
                aria-label="All status"
                value={statusValue}
                onChange={(e) => {
                  statusSearch(e);
                }}
              >
                <option value="">All</option>
                <option value="2">Active</option>
                <option value="1">Inactive</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-3">
            <Link
              to="/admin/addadmin"
              className="btn btn-white-button fl-right"
            >
              + Add Admin
            </Link>
          </div>
        </div>
        <div className="row mg-top20">
          {speciesList.backOfficeUsers &&
            speciesList.backOfficeUsers.map((item) => {
              return (
                <div className="col-md-4">
                  <div className="dashboard-card-wrapper admin-min-height">
                    <div className="dashboard-card-body wid100 dashboard-card-body-card">
                      <div className="dis-title-status">
                        <h5 className="heading-text"> {item.name}</h5>
                        {/* <span className='right-hightlight-msg reject-color'>{item.isSaplingMapped === true ? <span style={{ color: "green" }}>Mapped</span> : "Not-mapped"}</span> */}

                        <span className="status-text right-hightlight-msg">
                          {item.status === "INACTIVE" ? (
                            <span style={{ color: "red" }}>Inactive</span>
                          ) : item.status === "ACTIVE" ? (
                            "Active"
                          ) : null}
                        </span>
                      </div>
                      <div className="donor-list-padding">
                        {/* <p className='date-time-projectName'> {item.firebaseId} </p> */}
                        <p className="date-time-text"> {item.emailAddress}</p>
                      </div>
                      <div className="item-direction-wr">
                        <span className="gap-circle-icon">
                          {" "}
                          <button
                            className="circle-view"
                            to="/admin/adminedit"
                            onClick={() => {
                              editAdmin(item);
                            }}
                          >
                            {" "}
                            <img
                              className="img-fluid"
                              src={editiconpencil}
                              alt="bellicon"
                            />
                          </button>{" "}
                        </span>
                        {/* <span className='gap-circle-icon'>
                      {' '}
                      <button className='circle-view' to='/admin/dashboardview'>
                        {' '}
                        <img className='img-fluid bin-w-pad' onClick={() => { deleteProject(item.id) }} src={binicon} alt='bellicon' />
                      </button>{' '}
                    </span> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {speciesList.totalNoOfRecords === 0 ? <p>No Records Found</p> : null}
        {loading === false && speciesList.totalNoOfRecords > 10 ? (
          <div>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={speciesList.totalNoOfRecords}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        ) : null}
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}
